import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { ReactComponent as InventoryIcon } from '../../images/Inventory.svg';
import { ReactComponent as AlertCloseIcon } from '../../images/AlertCloseIcon.svg';
import classNames from 'classnames';

const Notification = ({
  action,
  icon,
  critical,
  handleClose,
  link,
  message,
  warning,
  info,
  textStyle,
  success,
  className,
  ...rest
}) => (
  <Wrapper
    className={className}
    role="alert"
    critical={critical}
    warning={warning}
    info={info}
    success={success}
    {...rest}
  >
    <Container>
      <IconContainer className={iconClass}>{icon || <InventoryIcon />}</IconContainer>
      <Content>
        <Text style={textStyle}>{message}</Text>
        {typeof link === 'function' ? (
          <ActionLink
            onClick={() => {
              link();
            }}
          >
            {action}
          </ActionLink>
        ) : (
          <Link href={link} onClick={() => handleClose()}>
            {action}
          </Link>
        )}
      </Content>
    </Container>
    <CloseContainer>
      <AlertCloseIcon style={closeIcon} aria-label="close" tabIndex={0} onClick={() => handleClose()} />
    </CloseContainer>
  </Wrapper>
);

const colors = {
  critical: '#ff647c',
  warning: '#fdc300',
  info: '#35CAE2',
  success: '#3fda98'
};

const types = {
  critical: css`
    border: 1px solid ${colors['critical']};
    border-left: 8px solid ${colors['critical']};
  `,
  warning: css`
    border: 1px solid ${colors['warning']};
    border-left: 8px solid ${colors['warning']};
  `,
  info: css`
    border: 1px solid ${colors['info']};
    border-left: 8px solid ${colors['info']};
  `,
  success: css`
    border: 1px solid ${colors['success']};
    border-left: 8px solid ${colors['success']};
  `
};

const Wrapper = styled.div`
  width: 431px;
  min-height: 92px;
  height: props => props?.height ? props.height : auto;
  display: flex;
  padding: 6px 9px;
  position: fixed;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  align-items: center;
  font-family: Sofia Pro;
  border-radius: 3px 5px 5px 3px;
  justify-content: space-between;
  background-color: #fff;
  ${props => props.critical && types['critical']};
  ${props => props.warning && types['warning']};
  ${props => props.info && types['info']};
  ${props => props.success && types['success']};
  ${props => `bottom: ${props.warning ? '35px' : '140px'}`};
  left: 52px;
  z-index: 1200;
}
`;

const Container = styled.div`
  display: flex;
  justifycontent: space-between;
  align-items: center;
  min-width: 80%;
`;

const IconContainer = styled.div`
  width: 50px;
  height: 50px;
  padding: 13px;
  border-radius: 50%;
  background-color: rgba(238, 241, 249, 0.5);
  position: relative;
  &.critical {
    background-color: #fae6e6;
  }
`;

const CloseContainer = styled.div`
  display: flex;
  svg {
    transition: all 0.3s ease;
    &:hover {
      transform: scale(1.1);
      filter: invert(1);
      opacity: 0.3;
    }
  }
`;

const Content = styled.div`
  margin-left: 16px;
`;

const Text = styled.p`
  color: rgba(0, 0, 0, 0.89);
  min-width: 257px;
  font-size: 14px;
  font-family: Sofia Pro;
  line-height: 19px;
  letter-spacing: 0;
`;

export const Link = styled.a`
  color: #587cc8;
  font-size: 13px;
  font-weight: 200;
  line-height: 20px;
`;

const ActionLink = styled.button`
  color: #587cc8;
  font-size: 13px;
  font-weight: 200;
  line-height: 20px;
  text-decoration: underline;
  background: unset;
  border: unset;
  cursor: pointer;
`;

const closeIcon = {
  minHeight: '20px',
  minWidth: '20px',
  borderRadius: '50%',
  cursor: 'pointer',
  marginTop: '-35px'
};

const iconClass = classNames({
  critical: 'critical',
  warning: 'warning',
  info: 'info',
  success: 'success'
});

Notification.propTypes = {
  action: PropTypes.node.isRequired,
  classes: PropTypes.object,
  critical: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  link: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  message: PropTypes.node.isRequired,
  warning: PropTypes.bool,
  success: PropTypes.bool,
  info: PropTypes.bool,
  icon: PropTypes.node,
  textStyle: PropTypes.object
};

export default Notification;
