import React, { Fragment, useEffect, useState } from 'react';
import Select from 'react-select';
import { withRouter } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Button from 'mpharma-components-library/lib/Button';
import { Text, useMpharmaI18n } from 'mpharma-i18n';
import LanguageNotification from './LanguageNotification';

const TIMER = 4000;

function LanguageSelector({ open, setOpen }) {
  const { changeLanguage, currentLanguage, availableLanguages, translateKey } = useMpharmaI18n();
  const [selectedLanguage, setSelectedLanguage] = useState(() => ({
    value: currentLanguage,
    label: translateKey(currentLanguage.split('-')[0])
  }));
  const [show, setShow] = useState(false);
  const languageOptions = availableLanguages.map(lang => ({ value: lang, label: translateKey(lang) }));

  useEffect(() => {
    let timer;
    if (show) {
      timer = setTimeout(() => setShow(false), TIMER);
    }
    return () => clearTimeout(timer);
  }, [show]);

  const handleChange = value => {
    setSelectedLanguage(value);
  };

  const handleSelect = () => {
    setOpen({ openChangeLanguage: false });
    changeLanguage(selectedLanguage.value);
    setShow(true);
  };

  const handleCancel = () => {
    setOpen({ openChangeLanguage: false });
  };

  return (
    <Fragment>
      <Dialog
        maxWidth={'sm'}
        fullWidth={true}
        open={open}
        aria-labelledby="form-dialog-title"
        PaperProps={{ style: { overflowY: 'visible', padding: '24px 20px' } }}
        data-testid="change-language-dialog"
        onClose={handleCancel}
      >
        <p style={styles.dialogTitle}>
          <Text i18nKey="common.modals.selectLanguage">Select Language</Text>
        </p>

        <main style={{ overflowY: 'visible' }}>
          <Select
            name="language"
            onChange={handleChange}
            options={languageOptions}
            value={selectedLanguage}
            data-testid="language-select"
            components={{ IndicatorSeparator: null }}
            theme={theme => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: '#F9F9FA',
                primary: '#35CAE2'
              }
            })}
          />
        </main>
        <DialogActions>
          <Button variant={'secondary'} onClick={handleCancel} data-testid="cancel-button">
            <Text i18nKey="common.cancel">Cancel</Text>
          </Button>
          <Button disabled={!selectedLanguage} onClick={handleSelect} data-testid="update-button">
            <Text i18nKey="common.update">Update</Text>
          </Button>
        </DialogActions>
      </Dialog>

      {show && (
        <LanguageNotification
          message={
            <Text i18nKey="common.modals.languageChanged" variables={{ language: selectedLanguage.label }}>
              {' '}
              The language has been changed to {selectedLanguage.label}
            </Text>
          }
          info
          action={<Text i18nKey="common.modals.languageSettings">Language settings</Text>}
          handleClose={() => setShow(false)}
          link="#"
          data-testid="language-notification"
        />
      )}
    </Fragment>
  );
}

const styles = {
  dialogTitle: {
    fontSize: 20,
    fontFamily: 'Sofia Pro',
    marginBottom: 20
  },
  subtitleStyle: {
    fontSize: 16,
    fontFamily: 'Sofia Pro'
  }
};

export default withRouter(LanguageSelector);
