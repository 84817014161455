import React from 'react';
import { styled } from '@mui/material/styles';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

const PREFIX = 'Breadcrumb';

const classes = {
  header: `${PREFIX}-header`,
  backIcon: `${PREFIX}-backIcon`
};

const Root = styled('div')({
  [`& .${classes.header}`]: {
    color: '#FF4F00',
    fontFamily: 'Sofia Pro',
    fontSize: '20px',
    marginLeft: '16px',
    fontWeight: 'bold',
    lineHeight: '28px'
  },
  [`& .${classes.backIcon}`]: {
    paddingTop: '5px',
    marginLeft: '24px',
    cursor: 'pointer',
    color: '#959595'
  }
});

export function Breadcrumb({ history, onClose, title }) {
  function goBack() {
    history.goBack();
  }

  return (
    <Root style={{ display: 'flex', paddingTop: '25px' }} data-testid="breadcrumb">
      <ArrowBack data-testid="backArrow" onClick={onClose || goBack} className={classes.backIcon} tabIndex={0} aria-label="back arrow" />
      <h1 className={classes.header}>{title}</h1>
    </Root>
  );
}

Breadcrumb.propTypes = {
  history: PropTypes.object,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  onClose: PropTypes.func
};

export default withRouter(Breadcrumb);
