import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { renderAppLinks } from '../../helpers/utils';
import { DRAWER_WIDTH } from '../../helpers/constants';
import { ReactComponent as HuggingFaceEmoji } from '../../images/HuggingFace.svg';
import { Text, useMpharmaI18n } from 'mpharma-i18n';
import { useMixpanel } from './Mixpanel/Provider';
import { useLocalStorage } from 'react-use';

const PREFIX = 'Home';

const classes = {
  container: `${PREFIX}-container`,
  header: `${PREFIX}-header`,
  salutation: `${PREFIX}-salutation`,
  welcome: `${PREFIX}-welcome`,
  innerContainer: `${PREFIX}-innerContainer`,
  categoryName: `${PREFIX}-categoryName`,
  moduleCard: `${PREFIX}-moduleCard`
};

const Root = styled('div')(() => ({
  [`& .${classes.container}`]: {
    minHeight: '100vh',
    backgroundColor: '#F2F2F2',
    padding: '16px',

    '@media (min-width: 768px)': {
      padding: `117px ${DRAWER_WIDTH + 76}px`
    }
  },

  [`& .${classes.header}`]: {
    marginTop: '48px',
    marginBottom: '48px',

    '@media (min-width: 768px)': {
      marginTop: 0
    }
  },

  [`& .${classes.salutation}`]: {
    fontSize: 30,
    marginTop: '0.7rem',
    lineHeight: '1rem',
    justifyContent: 'center',

    '@media (min-width: 768px)': {
      justifyContent: 'flex-start'
    }
  },

  [`& .${classes.welcome}`]: {
    fontWeight: 500,
    fontSize: 20,
    marginTop: '0.5rem'
  },

  [`& .${classes.innerContainer}`]: {
    display: 'flex',
    marginBottom: 10,
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'center',

    '@media (min-width: 768px)': {
      justifyContent: 'flex-start'
    }
  },

  [`& .${classes.categoryName}`]: {
    fontSize: 18,
    marginBottom: 9,
    fontWeight: 'normal'
  },

  [`&.${classes.moduleCard}`]: {
    height: 192,
    width: 199,
    backgroundColor: 'white',
    boxShadow: '0 0 4px -1px rgba(0,0,0,0.2)',
    borderRadius: 3,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 17,
    marginBottom: 17,
    flexDirection: 'column',
    cursor: 'pointer',
    fontWeight: 500,
    padding: '2rem',
    textAlign: 'center',
    '&:hover': {
      borderRadius: '6px',
      backgroundColor: '#F9F9F9',
      boxShadow: '0 12px 33px 0 rgba(0, 0, 0, 0.1)'
    },
    '&:nth-child(even)': {
      marginLeft: 0
    },
    '&:first-child': {
      marginLeft: 0
    }
  }
}));

function Home({ history, contractType }) {
  const [user] = useLocalStorage('bloom:user');
  const mixpanel = useMixpanel();
  const { translateKey } = useMpharmaI18n();

  function mapAppLinkToUI({ HomeIcon, handleClick, tooltip }, id) {
    return (
      <Root
        className={classes.moduleCard}
        onClick={() => {
          mixpanel.track('Click Bloom App', { app_name: translateKey(tooltip, { lng: 'en' }) });
          handleClick();
        }}
        key={id}
        data-testid={`home-page-${tooltip}`}
        tabIndex={0}
        aria-label={translateKey(tooltip)}
      >
        {HomeIcon && <HomeIcon height={50} width={50} />}
        <Text i18nKey={tooltip} />
      </Root>
    );
  }

  return (
    <Root data-testid="home-page-wrapper">
      <div className={classes.container}>
        <header className={classes.header}>
          <HuggingFaceEmoji data-testid="hugging-face-emoji" />
          <h1 className={classes.salutation}>
            <Text
              i18nKey="home.salutation"
              variables={{
                firstName: `${user && user.first_name}`,
                punctuation: ','
              }}
            >
              Hi, {user && user.first_name}
            </Text>
          </h1>
          <h3 className={classes.welcome}>
            <Text i18nKey="home.welcomeMessage">Welcome to Bloom</Text>
          </h3>
        </header>
        <h4 className={classes.categoryName}>
          <Text i18nKey="home.pharmacyManagement">Pharmacy Management</Text>
        </h4>
        <div className={classes.innerContainer}>
          {renderAppLinks(user, history, contractType)
            .filter(link => link.category === 'Pharmacy_Management')
            .map(mapAppLinkToUI)}
        </div>
        <h4 className={classes.categoryName}>Mutti</h4>
        <div className={classes.innerContainer}>
          {renderAppLinks(user, history, contractType)
            .filter(link => link.category === 'My_Mutti')
            .map(mapAppLinkToUI)}
        </div>
      </div>
    </Root>
  );
}

Home.propTypes = {
  history: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  contractType: PropTypes.string.isRequired
};

export default Home;
