import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import Button from 'mpharma-components-library/lib/Button';
import { Text } from 'mpharma-i18n';

const PREFIX = 'BloomVersionDialog';

const classes = {
  container: `${PREFIX}-container`,
  top: `${PREFIX}-top`,
  title: `${PREFIX}-title`,
  icon: `${PREFIX}-icon`,
  body: `${PREFIX}-body`,
  text: `${PREFIX}-text`,
  groupButton: `${PREFIX}-groupButton`,
  bodyMain: `${PREFIX}-bodyMain`,
  noBloomModule: `${PREFIX}-noBloomModule`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')({
  [`& .${classes.container}`]: {
    paddingTop: '25px',
    paddingBottom: '25px',
    width: '500px',
    paddingLeft: '32px',
    paddingRight: '32px',
    backgroundColor: '#ffffff',
    overflow: 'auto',
    boxShadow: '0 12px 33px 0 rgba(0, 0, 0, 0.1)'
  },
  [`& .${classes.top}`]: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 22
  },
  [`& .${classes.title}`]: {
    color: 'rgba(0,0,0,0.89)',
    fontFamily: 'Sofia Pro',
    fontSize: '24px',
    lineHeight: '32px'
  },
  [`& .${classes.icon}`]: {
    cursor: 'pointer',
    display: 'flex',
    fill: '#999999',
    justifyContent: 'flex-end'
  },
  [`& .${classes.body}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start'
  },
  [`& .${classes.text}`]: {
    color: 'rgba(0,0,0,0.89)',
    marginBottom: 10
  },
  [`& .${classes.groupButton}`]: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: '25px'
  },
  [`& .${classes.bodyMain}`]: {
    paddingLeft: '30px'
  },
  [`& .${classes.noBloomModule}`]: {
    marginTop: '20px'
  }
});

export function BloomVersionDialog({ handleClose, bloomVersions }) {
  return (
    <Root>
      <div className={classes.container}>
        <div className={classes.top}>
          <p className={classes.title}>
            <Text i18nKey="home.bloomModulesVersions">Bloom Modules Versions</Text>
          </p>
          <CloseIcon
            aria-label="close"
            tabindex={0}
            onClick={() => handleClose('openBloomVersionModal')}
            classes={{ root: classes.icon }}
          />
        </div>
        <div className={classes.body}>
          <p>
            <Text i18nKey="home.bloomModulesVersions.description">Versions</Text>
          </p>
          {bloomVersions && Object.keys(bloomVersions).length > 0 ? (
            <div className={classes.bodyMain}>
              <ul>
                {Object.keys(bloomVersions).map(key => (
                  <li key={key} data-testid={bloomVersions[key].key}>
                    <Text i18nKey={bloomVersions[key].key}>Module version</Text>:{' '}
                    <span>{bloomVersions[key].version}</span>
                  </li>
                ))}
              </ul>
            </div>
          ) : (
            <div className={classes.noBloomModule}>
              <p>
                <Text i18nKey="home.noBloomModule">No module loaded</Text>
              </p>
            </div>
          )}
        </div>

        <div className={classes.groupButton}>
          <Button aria-label='close' variant={'secondary'} onClick={handleClose} tabIndex={0}>
            <Text i18nKey="common.close">Close</Text>
          </Button>
        </div>
      </div>
    </Root>
  );
}

BloomVersionDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  bloomVersions: PropTypes.object.isRequired
};

export default BloomVersionDialog;
