import { DRAWER_WIDTH } from '../helpers/constants';
import { styled } from '@mui/material/styles';

export const selectStyles = {
  container: containerStyles => ({
    ...containerStyles,
    height: '40px',
    width: '100%'
  }),
  placeholder: placeholderStyles => ({
    ...placeholderStyles,
    color: '#000',
    fontSize: '14px',
    opacity: '0.89',
    height: '20px',
    lineHeight: '20px',
    alignItems: 'center'
  }),
  valueContainer: valueContainerStyles => ({
    ...valueContainerStyles,
    height: '40px',
    fontSize: '14px'
  }),
  menu: menuStyles => ({
    ...menuStyles,
    marginTop: 1,
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 12px 16px -4px rgba(16, 24, 40, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05)',
    borderRadius: '8px'
  }),
  option: (opinionStyles, state) => ({
    ...opinionStyles,
    height: '40px',
    fontSize: '14px'
  }),
  control: controlStyles => ({
    ...controlStyles,
    minHeight: '40px',
    height: '40px',
    fontSize: '14px'
  })
};

const STYLES_WRAPPER_PREFIX = 'StylesWrapper';

export const StylesWrapperClasses = {
  container: `${STYLES_WRAPPER_PREFIX}-container`,
  top: `${STYLES_WRAPPER_PREFIX}-top`,
  title: `${STYLES_WRAPPER_PREFIX}-title`,
  icon: `${STYLES_WRAPPER_PREFIX}-icon`,
  coloredBody: `${STYLES_WRAPPER_PREFIX}-coloredBody`,
  body: `${STYLES_WRAPPER_PREFIX}-body`,
  description: `${STYLES_WRAPPER_PREFIX}-description`,
  reasonsTitle: `${STYLES_WRAPPER_PREFIX}-reasonsTitle`,
  otherReasons: `${STYLES_WRAPPER_PREFIX}-otherReasons`,
  otherReasonsInput: `${STYLES_WRAPPER_PREFIX}-otherReasonsInput`,
  text: `${STYLES_WRAPPER_PREFIX}-text`,
  groupButton: `${STYLES_WRAPPER_PREFIX}-groupButton`
};

export const StylesWrapper = styled('div')(({ theme }) => ({
  [`& .${StylesWrapperClasses.container}`]: {
    paddingTop: '25px',
    paddingBottom: '25px',
    maxWidth: '616px',
    paddingLeft: '32px',
    paddingRight: '32px',
    backgroundColor: '#ffffff',
    overflow: 'visible',
    boxShadow: '0px 26px 64px rgba(0, 0, 0, 0.07)'
  },
  [`& .${StylesWrapperClasses.top}`]: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 8
  },
  [`& .${StylesWrapperClasses.title}`]: {
    color: '#000000',
    fontFamily: 'Sofia Pro',
    fontWeight: 800,
    fontSize: '20px',
    fontStyle: 'normal',
    lineHeight: '32px',
    alignItems: 'centre'
  },
  [`& .${StylesWrapperClasses.icon}`]: {
    cursor: 'pointer',
    display: 'flex',
    fill: '#999999',
    marginLeft: '95%',
    width: '13.5px',
    height: '13.5px'
  },
  [`& .${StylesWrapperClasses.coloredBody}`]: {
    display: 'flex',
    justifyContent: 'centre',
    backgroundColor: 'rgba(53, 202, 226, 0.1)',
    borderRadius: 6,
    width: '396px',
    height: '69px',
    left: '522px',
    top: '323px'
  },
  [`& .${StylesWrapperClasses.body}`]: {
    display: 'flex',
    justifyContent: 'centre',
    borderRadius: 6,
    width: '396px',
    height: '69px',
    left: '522px',
    top: '323px',
    marginBottom: 32
  },
  [`& .${StylesWrapperClasses.description}`]: {
    color: '#000000',
    fontFamily: 'Sofia Pro',
    fontWeight: 400,
    fontSize: '13px',
    fontStyle: 'normal',
    lineHeight: '20px',
    alignItems: 'centre',
    height: '40px',
    maxWidth: '367px',
    margin: '16px 0 0 16px'
  },
  [`& .${StylesWrapperClasses.reasonsTitle}`]: {
    width: '396px',
    height: '20px',
    fontFamily: 'Sofia Pro',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    display: 'flex',
    alignItems: 'center',
    color: '#384853',
    paddingBottom: '4px'
  },
  [`& .${StylesWrapperClasses.otherReasons}`]: {
    fontFamily: 'Sofia Pro',
    fontWeight: 400,
    fontSize: '13px',
    fontStyle: 'normal',
    lineHeight: '20px',
    alignItems: 'centre',
    height: '40px',
    maxWidth: '396px',
    paddingBottom: '4px',
    margin: '16px 0 24px 0'
  },
  [`& .${StylesWrapperClasses.otherReasonsInput}`]: {
    '&:focus': {
      borderColor: '#35CAE2 !important'
    }
  },
  [`& .${StylesWrapperClasses.text}`]: {
    color: 'rgba(0,0,0,0.89)',
    marginBottom: 10
  },
  [`& .${StylesWrapperClasses.groupButton}`]: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: '25px'
  }
}));

function arrowGenerator(color) {
  return {
    '&[x-placement*="bottom"] $arrow': {
      top: 0,
      left: 0,
      marginTop: '-0.95em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '0 1em 1em 1em',
        borderColor: `transparent transparent ${color} transparent`
      }
    },
    '&[x-placement*="top"] $arrow': {
      bottom: 0,
      left: 0,
      marginBottom: '-0.95em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '1em 1em 0 1em',
        borderColor: `${color} transparent transparent transparent`
      }
    },
    '&[x-placement*="right"] $arrow': {
      left: 0,
      marginLeft: '-0.95em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 1em 1em 0',
        borderColor: `transparent ${color} transparent transparent`
      }
    },
    '&[x-placement*="left"] $arrow': {
      right: 0,
      marginRight: '-0.95em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 0 1em 1em',
        borderColor: `transparent transparent transparent ${color}`
      }
    }
  };
}

const PREFIX = 'Layout';

export const classes = {
  root: `${PREFIX}-root`,
  listItem: `${PREFIX}-listItem`,
  listItemIcon: `${PREFIX}-listItemIcon`,
  drawers: `${PREFIX}-drawers`,
  selectDropdownOption: `${PREFIX}-selectDropdownOption`,
  avatar: `${PREFIX}-avatar`,
  avatarRoot: `${PREFIX}-avatarRoot`,
  container: `${PREFIX}-container`,
  drawer: `${PREFIX}-drawer`,
  appBar: `${PREFIX}-appBar`,
  menuButton: `${PREFIX}-menuButton`,
  toolbar: `${PREFIX}-toolbar`,
  drawerPaper: `${PREFIX}-drawerPaper`,
  content: `${PREFIX}-content`,
  paperAnchorDockedLeft: `${PREFIX}-paperAnchorDockedLeft`,
  badge: `${PREFIX}-badge`,
  badgeColor: `${PREFIX}-badgeColor`,
  arrow: `${PREFIX}-arrow`,
  htmlPopper: `${PREFIX}-htmlPopper`,
  htmlTooltip: `${PREFIX}-htmlTooltip`,
  salesDownloadTooltipText: `${PREFIX}-salesDownloadTooltipText`,
  salesDownloadingTooltipTitle: `${PREFIX}-salesDownloadingTooltipTitle`,
  salesDownloadedTooltipTitle: `${PREFIX}-salesDownloadedTooltipTitle`,
  salesDownloadTooltipIcon: `${PREFIX}-salesDownloadTooltipIcon`,
  notificationContainer: `${PREFIX}-notificationContainer`,
  icon: `${PREFIX}-icon`,
  salesNotice: `${PREFIX}-salesNotice`,
  selected: `${PREFIX}-selected`,
  intercomLauncher: `${PREFIX}-intercomLauncher`,
  noPrint: `${PREFIX}-noPrint`
};

export const LayoutStyleWrapper = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    backgroundColor: '#F2F2F2'
  },

  [`& .${classes.listItem}`]: {
    paddingTop: '11px',
    paddingBottom: '11px',
    paddingLeft: '32px',
    justifyContent: 'center'
  },
  [`& .${classes.listItemIcon}`]: {
    color: 'rgba(0, 0, 0, 0.54)',
    display: 'inline-flex',
    flexShrink: 0,
    marginRight: '16px',
    minWidth: '0px'
  },
  [`& .${classes.drawers}`]: {
    transform: 'translate(-1%)',
    transition: 'transform 1000s ease-out'
  },
  [`& .${classes.selectDropdownOption}`]: {
    overflowY: 'visible'
  },
  [`& .${classes.avatar}`]: {
    backgroundColor: theme?.palette?.primary?.main
  },
  [`& .${classes.avatarRoot}`]: {
    fontFamily: 'Sofia Pro',
    fontSize: '16px',
    lineHeight: '21px'
  },
  [`& .${classes.container}`]: {
    height: '100%',
    display: 'flex',
    overflowY: 'scroll',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '-ms-overflow-style': 'none' /* IE and Edge */,
    'scrollbar-width': 'none' /* Firefox */,
    '&::-webkit-scrollbar': {
      width: '0.2em',
      backgroundColor: 'transparent'
    } /* Chrome, Safari and Opera */,
    '&:hover': {
      '&::-webkit-scrollbar': {
        width: '0.2em',
        backgroundColor: '#F9FAFB '
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#808384',
        borderRadius: '5px'
      },
      'scrollbar-width': 'thin',
      'scrollbar-color': 'auto',
      '-ms-overflow-style': 'auto'
    }
  },
  [`& .${classes.drawer}`]: {
    [theme?.breakpoints?.up('sm')]: {
      width: DRAWER_WIDTH,
      flexShrink: 0
    }
  },
  [`& .${classes.appBar}`]: {
    marginLeft: DRAWER_WIDTH,
    [theme?.breakpoints?.up('sm')]: {
      width: `calc(100% - ${DRAWER_WIDTH}px)`
    },
    boxShadow: 'none'
  },
  [`& .${classes.menuButton}`]: {
    [theme?.breakpoints?.up('sm')]: {
      display: 'none'
    }
  },
  [`& .${classes.toolbar}`]: theme?.mixins?.toolbar,
  [`& .${classes.drawerPaper}`]: {
    [theme?.breakpoints?.up('sm')]: {
      width: DRAWER_WIDTH
    },
    width: '70%'
  },
  [`& .${classes.content}`]: {
    flexGrow: 1
  },
  [`& .${classes.paperAnchorDockedLeft}`]: {
    boxShadow: '0 0 6px 0 rgba(0,0,0,0.08)',
    borderRight: 'none'
  },
  [`& .${classes.badge}`]: {
    width: 10,
    height: 10
  },
  [`& .${classes.badgeColor}`]: {
    backgroundColor: 'red'
  },
  [`& .${classes.noPrint}`]: {
    '@media print': {
      display: 'none !important'
    }
  },
  [`& .${classes.logo}`]: {
    cursor: 'pointer'
  },
  [`& .${classes.arrow}`]: {
    position: 'absolute',
    fontSize: 6,
    width: '3em',
    height: '3em',
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: 0,
      height: 0,
      borderStyle: 'solid'
    }
  },
  [`& .${classes.htmlPopper}`]: arrowGenerator('rgba(0,0,0,0.9)'),
  [`& .${classes.htmlTooltip}`]: {
    padding: '1.5rem',
    backgroundColor: 'rgba(0,0,0,0.9)',
    color: '#ffffff',
    maxWidth: 254,
    fontSize: theme?.typography?.pxToRem(12),
    '& b': {
      fontWeight: theme?.typography?.fontWeightMedium
    },
    borderRadius: '0.1rem',
    zIndex: 2000
  },
  [`& .${classes.salesDownloadTooltipText}`]: {
    fontSize: '10px'
  },
  [`& .${classes.salesDownloadingTooltipTitle}`]: {
    color: '#F7B500'
  },
  [`& .${classes.salesDownloadedTooltipTitle}`]: {
    color: '#44D7B6'
  },
  [`& .${classes.salesDownloadTooltipIcon}`]: {
    color: '#FDC300'
  },
  [`& .${classes.notificationContainer}`]: {
    alignItems: 'center',
    borderBottom: '1px solid lightgray',
    display: 'flex',
    height: 90,
    padding: '10px'
  },
  [`& .${classes.icon}`]: {
    marginRight: 15
  },
  [`& .${classes.salesNotice}`]: {
    fontSize: '13px',
    fontWeight: 700
  },
  [`& .${classes.menuItem}`]: {
    '&:focus-visible': {
      outline: '3px solid black',
      outlineOffset: '2px'
    }
  },
  [`& .${classes.selected}`]: {
    boxShadow: 'inset 5px 0px #FDC300'
  },
  [`& .${classes.intercomLauncher}`]: {
    border: 'transparent',
    backgroundColor: 'transparent',
    color: '#0000008a'
  }
}));
